import React from 'react';
import Footer from "../components/Footer";
import Faq from "../components/FAQ";
import {Link} from "react-router-dom";

const Servicos = ({ click, colors }) => {
    return (
        <div>
            <div className="flex flex-col min-h-screen w-screen">
                <div id='flex-container' className={`items-center flex flex-row flex-wrap justify-center pt-64 w-11/12 mx-auto text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl`}>
                    <div className={`font-sans ${colors["text"]} px-2`}>
                        NOSSOS
                    </div>
                    <div className={`font-sans font-bold ${colors["text"]} px-2`}>
                        SERVIÇOS
                    </div>
                </div>
                <div className="flex flex-row justify-center py-20 w-11/12 lg:w-4/5 mx-auto gap-1">
                    <a href='#projeto'>
                        <img src="/resources/imgs/servicos/projeto_arquitetonico.png" className="flex-1 px-10 hidden md:block" alt="PROJETO"/>
                        <img src="/resources/imgs/servicos/projeto_arquitetonico_slim.png" className="flex-1 px-2 md:hidden" alt="PROJETO"/>    
                    </a>
                    <a href='#consultoria'>
                        <img src="/resources/imgs/servicos/consultoria.png" className="flex-1 px-10 hidden md:block" alt="PROJETO"/>
                        <img src="/resources/imgs/servicos/consultoria_slim.png" className="flex-1 px-2 md:hidden" alt="PROJETO"/>    
                    </a>
                </div>
                <hr className={`border-t-2 ${colors["border"]} w-full`} />
                <div id='projeto' className="flex flex-col w-full items-center">
                    <div className="flex flex-col justify-center py-20 w-11/12 lg:w-4/5 items-center">
                        <img src="/resources/imgs/servicos/projeto_banner.png" alt="PROJETO BANNER"/>
                        <p className={`font-sans ${colors["text"]} italic w-4/5 text-2xl xl:text-3xl 2xl:text-4xl py-10 xl:py-16 text-center`}>Precisa quebrar paredes, integrar ambientes, repensar a parte elétrica ou refazer a sua cozinha?</p>
                        <p className={`font-sans ${colors["text"]}  text-2xl xl:text-3xl 2xl:text-4xl text-center pt-5`}>NESSA MODALIDADE DE PROJETO</p>
                        <p className={`font-sans ${colors["text"]} font-bold text-4xl lg:text-5xl text-center`}>TRANSFORMAMOS COMPLETAMENTE O SEU ESPAÇO</p>
                        <Link to="/contato" onClick={() => click("contato")} className={`border ${colors["border"]} border-2 rounded-full m-10`}>
                            <p className={`hidden md:block font-sans ${colors["text"]} text-xl lg:text-2xl text-center p-2`}> Essa é a modalidade de projeto para você, faça um orçamento!</p>
                            <p className={`md:hidden font-sans ${colors["text"]} text-xl lg:text-2xl text-center p-2`}> Faça um orçamento!</p>
                        </Link>
                        <div className="flex flex-row pt-10 mx-auto justify-center">
                            <img src="/resources/imgs/servicos/arquitetura_boxes_large.png" alt="servicos" className={"hidden lg:block"}/>
                            <img src="/resources/imgs/servicos/arquitetura_boxes_medium.png" alt="servicos" className={"hidden md:block lg:hidden"}/>
                            <img src="/resources/imgs/servicos/arquitetura_boxes_small.png" alt="servicos" className={"block md:hidden"}/>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center py-16 w-11/12 lg:justify-left">
                        <div className={`font-sans text-5xl xl:text-6xl ${colors["text"]} mx-auto lg:mx-0`}> MAS COMO </div>
                        <div className={`font-sans font-bold text-5xl xl:text-6xl ${colors["text"]} mx-auto lg:mx-0 pb-10 lg:pb-0 text-center lg:text-left`}> FUNCIONA O PROJETO? </div>
                        <img src="/resources/imgs/servicos/projeto_workflow.png" className={"py-5 hidden lg:block"} alt="PROJETO"/>
                        <img src="/resources/imgs/servicos/projeto_cards_medium.png" className={"py-5 hidden md:block lg:hidden"} alt="PROJETO"/>
                        <img src="/resources/imgs/servicos/projeto_cards_small.png" className={"py-5 block md:hidden"} alt="PROJETO"/>
                    </div>
                    <div id='consultoria' className="flex flex-col justify-center py-20 w-11/12 lg:w-4/5 items-center">    
                        <img src="/resources/imgs/servicos/consultoria_banner.png" className="py-10" alt="PROJETO BANNER"/>
                        <div className="flex flex-row pt-10 mx-auto justify-center">
                            <img src="/resources/imgs/servicos/arquitetura_boxes_large.png" alt="servicos" className={"hidden lg:block"}/>
                            <img src="/resources/imgs/servicos/arquitetura_boxes_medium.png" alt="servicos" className={"hidden md:block lg:hidden"}/>
                            <img src="/resources/imgs/servicos/arquitetura_boxes_small.png" alt="servicos" className={"block md:hidden"}/>
                        </div>
                    </div>
                    <div className="flex flex-col w-full bg-[#88B017] bg-opacity-15 items-center">
                        <div className={"p-10"}>
                            <div className={`font-sans text-3xl ${colors["text"]}`}>APENAS</div>
                            <div className={`font-sans font-bold text-8xl lg:text-9xl ${colors["text"]}`}>R$750,00</div>
                            <div className={`font-sans text-3xl ${colors["text"]}`}>POR AMBIENTE TRANSFORMADO</div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center py-20 w-11/12 lg:w-4/5 items-center">
                        <div className="flex flex-col py-32">
                            <div className={`font-sans text-5xl xl:text-6xl  ${colors["text"]} mx-auto lg:mx-0`}> MAS COMO </div>
                            <div className={`font-sans font-bold text-5xl xl:text-6xl ${colors["text"]} mx-auto lg:mx-0 pb-10 lg:pb-0 text-center lg:text-left`}> FUNCIONA A CONSULTORIA? </div>
                            <img src="/resources/imgs/servicos/consultoria_workflow.png" className={"py-5 hidden lg:block"} alt="PROJETO"/>
                            <img src="/resources/imgs/servicos/consultoria_cards_medium.png" className={"py-5 hidden md:block lg:hidden"} alt="PROJETO"/>
                            <img src="/resources/imgs/servicos/consultoria_cards_small.png" className={"py-5 block md:hidden"} alt="PROJETO"/>
                        </div>
                        <div className="flex flex-col w-full">
                            <div className="flex flex-col lg:flex-row pb-20 mx-auto lg:mx-0">
                                <div className={`font-sans text-4xl xl:text-5xl ${colors["text"]} px-1`}> QUAL É O SERVIÇO </div>
                                <div className={`font-sans font-bold text-4xl xl:text-5xl ${colors["text"]} px-1`}> CERTO PARA MIM? </div>
                            </div>
                            <img src="/resources/imgs/servicos/table.png" alt="servicos" className={""}/>
                            <div className="flex flex-row">
                                <img src="/resources/imgs/servicos/keyhole.svg" alt="KEYHOLE" className={"w-1/3 xl:1/5 p-10 self-start hidden lg:block"}/>
                                <div className={"w-full xl:w-4/5"}>
                                    <div className={`font-sans text-6xl text-[#6E852D] text-center w-full p-10`}>F.A.Q.</div>
                                    <div className={`font-sans text-4xl text-[#6E852D] text-center w-full`}>Perguntas Frequentes</div>
                                    <Faq color={colors}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mx-auto pb-10 w-11/12 lg:w-4/5 justify-center pt-8 md:pt-32 md:flex-row lg:flex-row-reverse">
                    <div className="flex flex-col flex-grow items-center justify-center px-2 lg:px-5 pb-20 xl:pb-0 w-3/5 mx-auto my-auto">
                        <div className={`font-sans underline text-4xl sm:text-5xl xl:text-6xl ${colors["text"]} my-8 mx-auto`}>Quer saber mais?</div>
                        <Link to="/contato" onClick={() => click("contato")} className={`border ${colors["border"]} border-2 rounded-full mx-auto`}>
                            <p className={`font-sans ${colors["text"]} text-md sm:text-xl lg:text-2xl text-center p-1.5`}> Preencha nosso formulário de orçamento</p>
                        </Link>
                    </div>
                    <div className={"mx-auto md:ml-auto w-2/5"}>
                        <img src="/resources/imgs/servicos/banner.png" alt="BANNER"/>
                    </div>/
                </div>
                <Footer colors={colors}/>
            </div>
        </div>
    );
};

export default Servicos;