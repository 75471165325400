import React from 'react';
import AccordionItem from "./AccordionItem";

const Faq = ( { color } ) => {
    const faq = [
        {
            "question":"Quanto custa um projeto?",
            "answer":"O projeto é um serviço totalmente personalizado e, por isso, não tem um preço fixo. É necessário entender as especificidades das demandas do projeto. Para ter um orçamento fiel, entre em contato pelo WhatsApp ou preencha o formulário disponível aqui no site.",
        },
        {
            "question":"Qual a diferença do projeto a distância para o projeto presencial?",
            "answer":"Para projetos à distância, a maior parte é feita on-line: indicação de lojas virtuais e/ou disponibilidade para tirar dúvidas via ligação no momento que o cliente estiver nas lojas; entrega de toda documentação virtualmente em PDF; assessoria de obra on-line, com disponibilidade para responder dúvidas dos profissionais das obras sempre que necessário. Além disso, contatos com arquitetos parceiros para fazer o levantamento.",
        },
        {
            "question":"Vocês indicam prestadores de serviço?",
            "answer":"Sim, indicamos! Mas você tem total liberdade para seguir com o profissional ou empresa que achar melhor, sejam as nossas indicações ou não.",
        },
        {
            "question":"Posso pedir alterações no projeto?",
            "answer":"Sim! O contrato do projeto já inclui uma revisão. Caso seja necessária uma segunda revisão, você pode contratar a parte - fique tranquilo, é um caso bem raro.",
        },
        {
            "question":"Vocês fazem acompanhamento de obra?",
            "answer":"Não! Para acompanhamento de obra, você deve buscar por uma empreiteira ou engenheiro. Nós fazemos assessoria de obra: que consiste em tirar as dúvidas dos profissionais quanto ao projeto a ser executado, que pode incluir visitas às obras a depender da demanda.",
        },
        {
            "question":"Quanto tempo demora um projeto?",
            "answer":"Depende. O projeto é totalmente personalizado e conta com demandas diferentes em cada caso - o tamanho, tempo de resposta e outros fatores impactam nesse prazo. Mas, a título de informação, um projeto grande dura, em média, 2 meses.",
        },
        {
            "question":"Como é a visualização do projeto para aprovação? Tem 3D?",
            "answer":"Sim! Na etapa de anteprojeto fazemos o projeto em 3D e você visualiza como ficará o espaço com imagens o mais realistas possível.",
        },
        {
            "question":"Como são os encontros durante o projeto?",
            "answer":"Todas as reuniões são on-line - seja para projetos a distância ou não. No caso de projetos presenciais, os encontros acontecem: no levantamento, nas visitas à obra e nas lojas.",
        },
        {
            "question":"Também trabalham com projetos comerciais?",
            "answer":"Sim! Entre em contato pelo WhatsApp ou pelo formulário de orçamento.",
        },
        {
            "question":"Posso contratar etapas do serviço separadas?",
            "answer":"Depende. Podemos fazer somente os detalhamento de marcenaria para sua cozinha, por exemplo. Já no caso do anteprojeto, não o entregamos à parte. Entre em contato para entendermos melhor sua demanda.",
        },
    ]


    return (
        <div className={"border-collapse pt-20"}>
        {faq.map((entry) => <AccordionItem title={entry["question"]} content={entry["answer"]} color={color}/>)}
        </div>
    );
};

export default Faq;