import React from 'react';

function Button({img_path, link} ) {
    return (
        <div className={"w-1/4 mx-auto"}>
            <a href={link}>
                <img src={img_path} alt=""/>
            </a>
        </div>
    );
}

export default Button;