import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function SetColorScheme( {setPage} ){
    const location = useLocation();
    useEffect(() => {
        // Perform some logic based on the current route
        if (location.pathname === '/') {
            setPage("home")
        }
        if (location.pathname === '/sobre') {
            setPage("sobre")
        }
        if (location.pathname.split("/")[1] === 'portfolio') {
            setPage("portfolio")
        }
        if (location.pathname === '/servicos') {
            setPage("servicos")
        }
        if (location.pathname === '/contato') {
            setPage("contato")
        }
    }, [location, setPage]);
}
