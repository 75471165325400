import React from 'react';
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const Sobre = ({ click, colors }) => {
    return (
        <div>
            <div className="flex flex-col min-h-screen w-screen">
                <div id='flex-container' className={`items-center flex flex-row flex-wrap justify-center pt-64 mx-auto text-6xl sm:text-7xl md:text-8xl xl:text-9xl`}>
                    <div className={`font-sans ${colors["text"]} px-1`}>
                        NOSSA
                    </div>
                    <div className={`font-sans font-bold ${colors["text"]} px-1`}>
                        HISTÓRIA
                    </div>
                </div>
                <div className="flex flex-col pt-32 lg:flex-row w-full">
                    <div className='hidden lg:block w-1/12'/>
                    <div className='w-2/3 md:w-1/2 lg:w-4/12 mx-auto lg:ml-auto'>
                        <img src="/resources/imgs/sobre/sobre-1.png" className={"hidden lg:block"} alt="FOTO 1"/>
                        <img src="/resources/imgs/sobre/sobre-1-round.png" className={"block lg:hidden mx-auto"} alt="FOTO 1"/>
                    </div>
                    <div className="flex flex-col pt-8 xl:pt-32 lg:pl-10 lg:w-7/12">
                        <div className={`font-sans font-bold mx-auto lg:mx-0 text-4xl sm:text-5xl md:text-6xl ${colors["text"]} pb-10`}>Oi! Tudo bem?</div>
                        <div className={`font-sans text-3xl sm:text-4xl md:text-5xl 2xl:text-6xl  ${colors["text"]} w-4/5 mx-auto lg:mx-0 lg:w-11/12 text-center lg:text-left`}>por aqui, estamos cansados <span className='font-bold'>de projetos chatos.</span> E por aí?</div>
                        <hr className={`hidden lg:block border-1 ${colors["border"]} mt-32 2xl:mt-40`}/>
                        <div className={`pt-16 lg:pt-6 xl:pt-10 font-sans italic text-xl md:text-2xl 2xl:text-3xl ${colors["text"]} mx-auto lg:mx-0 w-9/12 lg:w-11/12 text-center lg:text-left leading-none`}>Eu sou Jordhana Raposo, arquiteta que tem a felicidade como o porquê de todas as minhas ações. Acredito fortemente que sua casa deve te fazer genuinamente feliz e estou aqui para te ajudar nesse objetivo!</div>
                        <hr className={`block lg:hidden border-1 ${colors["border"]} mt-32 2xl:mt-40`}/>
                    </div>
                </div>
                <div className="hidden lg:block mr-40 pt-20 w-11/12">
                    <div className={`font-sans font-bold text-5xl ${colors["text"]} text-right ml-auto w-1/2`}>o escritório surgiu da vontade de fazer diferente</div>
                </div>
                <div className="flex flex-col-reverse pt-16 lg:flex-row w-full">
                    <div className="flex flex-col lg:pr-20 lg:w-7/12">
                        <div className={`flex flex-col w-10/12 md:w-9/12 lg:w-11/12 xl:w-9/12 mx-auto lg:ml-auto text-center lg:text-left`}>
                            <div className={`font-sans italic text-xl md:text-2xl 2xl:text-3xl ${colors["text"]} leading-none`}>Quando eu tinha 12 anos, o projeto realizado no meu quarto me marcou de forma totalmente negativa. Nada do que eu havia pedido estava ali e nunca me senti representada naquele cômodo. Esse foi o grande motivador para eu sempre faça os meus projetos de forma diferente. <br/><br/> Fã de tudo que é alegre, colorido e divertido, quero que o lugar que você mora seja, de fato, a SUA casa. Seja para reformar, para construir do zero ou para alguma consultoria, conte comigo para transformar seu cantinho.</div>
                            <div className={`font-sans font-bold mx-auto lg:mx-0 text-4xl sm:text-5xl 2xl:text-6xl xl:w-4/5 ${colors["text"]} pt-8 xl:pt-10 pb-5 xl:pb-10`}>por que não sair do básico, afinal?</div>
                        </div>
                        <hr className={`hidden lg:block border-1 ${colors["border"]} mt-5`}/>
                        <hr className={`block lg:hidden border-1 ${colors["border"]} mt-5`}/>
                    </div>
                    <div className={`lg:hidden font-sans font-bold text-3xl sm:text-4xl md:text-5xl ${colors["text"]} text-center mx-auto w-9/12 pt-10 pb-10`}>o escritório surgiu da vontade de fazer diferente</div>
                    <div className='w-11/12 md:w-9/12 lg:w-4/12 mx-auto lg:ml-auto'>
                        <img src="/resources/imgs/sobre/sobre-2.png" className={"hidden lg:block"} alt="FOTO 2"/>
                        <img src="/resources/imgs/sobre/sobre-2-large.png" className={"block lg:hidden mx-auto"} alt="FOTO 1"/>
                    </div>
                    <div className='hidden lg:block w-1/12'/>
                </div>
                <div className="flex flex-col pt-32 lg:flex-row w-full">
                    <div className='hidden lg:block w-1/12'/>
                    <div className='w-11/12 md:w-9/12 lg:w-4/12 mx-auto lg:ml-auto'>
                        <img src="/resources/imgs/sobre/sobre-3.png" className={"hidden lg:block"} alt="FOTO 1"/>
                        <img src="/resources/imgs/sobre/sobre-3-large.png" className={"block lg:hidden mx-auto"} alt="FOTO 1"/>
                    </div>
                    <div className="flex flex-col pt-8 xl:pt-32 lg:pl-10 lg:w-7/12">
                        <hr className={`hidden lg:block border-1 ${colors["border"]} mt-8 mb-8 2xl:mt-16 2xl:mb-16`}/>
                        <div className='w-10/12 md:w-9/12 lg:w-10/12 xl:w-9/12 mx-auto lg:ml-auto text-center lg:text-right'>
                            <div className={`font-sans font-bold mx-auto lg:mx-0 text-3xl sm:text-4xl md:text-5xl 2xl:text-6xl ${colors["text"]} pb-10`}>o que nós defendemos?</div>
                            <div className={`font-sans italic text-xl md:text-2xl 2xl:text-3xl ${colors["text"]} leading-none`}>Nossa razão de existir é permitir que pessoas contem suas histórias de um jeito criativo, através de projetos arquitetônicos originais e com personalidade.</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-screen content-end pb-10 pt-8 md:pt-16 lg:pt-32 xl:flex-row">
                <div className="flex flex-col flex-grow items-center justify-center px-2 lg:px-10 pb-20 xl:pb-0 w-3/5 mx-auto">
                        <div className={`font-sans underline text-5xl lg:text-6xl ${colors["text"]} my-8 mx-auto`}>Não se acanhe!</div>
                        <Link to="/contato" onClick={() => click("contato")} className={`border ${colors["border"]} border-2 rounded-full mx-auto`}>
                            <p className={`font-sans ${colors["text"]} text-xl lg:text-2xl text-center p-2`}> Preencha nosso formulário de orçamento</p>
                        </Link>
                    </div>
                    <div className={"ml-auto w-3/5"}>
                        <img src="/resources/imgs/sobre/banner.png" alt="BANNER"/>
                    </div>/
                </div>

                <Footer colors={colors}/>
            </div>
        </div>
    );
};

export default Sobre;