import React from 'react';
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const Home = ({ click, colors }) => {
    return (
        <div>
            <div className="flex flex-col min-h-screen w-screen">
                <div id='flex-container' className={`items-center flex flex-row flex-wrap justify-center pt-44 xl:pt-64 w-screen`}>
                    <img src="/resources/imgs/home/header.png" className={"w-11/12"} alt="header"/>
                </div>
                <div className="flex flex-row flex-grow justify-center pt-10 pb-10 lg:pb-28 w-screen">
                    <img src="/resources/imgs/home/capa.png" className={"w-11/12"} alt="capa"/>
                </div>
                <div className={"flex flex-row flex-grow justify-center w-screen pt-20"}>
                    <div className="flex flex-col w-11/12">
                        <div className={`flex flex-row flex-wrap pb-10 mx-auto md:mx-0 text-6xl lg:text-7xl`}>
                            <div className={`font-sans ${colors["text"]} px-2`}>
                                SEJA
                            </div>
                            <div className={`font-sans font-bold ${colors["text"]} px-2`}>
                                BEM-VINDO!
                            </div>
                        </div>
                        <div className="flex flex-col justify-center md:flex-row">
                            <div className="flex px-10 py-2 md:py-10 lg:py-0 items-center w-2/5 mx-auto">
                                <img src="/resources/imgs/home/cachorro.png" alt="Cachorro" className={"object-cover max-h-64 lg:max-h-96 mx-auto"}/>
                            </div>
                            <div className="flex flex-col mx-auto w-4/5 md:w-3/5">
                                <div className={`border ${colors["border"]} border-2 rounded-full p-2 my-5 lg:p-4 lg:my-10`}>
                                    <p className={`font-sans ${colors["text"]} italic text-2xl lg:text-3xl text-center leading-7 p-1 lg:p-2`}>Nós somos um escritório de arquitetura para pessoas cansadas de projetos chatos.</p>
                                </div>
                                <img src="/resources/imgs/home/barra.png" className={"mt-4 mb-8"} alt="Barra"/>
                                <div className={"flex flex-col flex-grow"}></div>
                                <img src="/resources/imgs/home/apresentacao_servicos.png" alt="servicos" className={"hidden lg:block"}/>
                                <img src="/resources/imgs/home/apresentacao_servicos_small.png" alt="servicos" className={"block lg:hidden"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center w-4/5 mx-auto">
                    <img src="/resources/imgs/home/portfolio_header.png" className={"pb-5 md:pb-10 lg:pb-20 pt-32 w-3/5"} alt="HEADER"/>
                    <div className={"pb-5 lg:pb-10"}>
                        <img src="/resources/imgs/home/portfolio_images.png" alt="IMAGES"/>
                    </div>
                    <Link to="/portfolio" onClick={() => click("portfolio")} className={`border ${colors["border"]} border-2 rounded-full m-2 md:px-3`}>
                        <div className={`font-sans text-md md:text-xl lg:text-2xl ${colors["text"]} my-1 mx-2`}>Ver mais</div>
                    </Link>
                </div>
                <div className="flex flex-col justify-center items-center py-20 lg:py-32 w-4/5 mx-auto text-2xl md:text-4xl">
                    <div className={`flex pb-5 flex-row`}>
                        <div className={`font-sans ${colors["text"]} px-1 mx-auto xl:mx-0`}>
                            SUA CASA TEM DE
                        </div>
                        <div className={`font-sans font-bold ${colors["text"]} px-1 mx-auto xl:mx-0`}>
                            TER A SUA CARA
                        </div>
                    </div>
                    <img src="/resources/imgs/home/chamada.png" alt="CHAMADA"/>
                </div>
                <div className="flex flex-col w-full pb-10 md:flex-row px-0">
                    <div className="flex flex-col flex-grow items-center justify-center px-2 lg:px-10 pb-20 xl:pb-0 w-3/5 mx-auto">
                        <div className={`font-sans underline text-5xl lg:text-6xl ${colors["text"]} my-8 mx-auto`}>Não se acanhe!</div>
                        <Link to="/contato" onClick={() => click("contato")} className={`border ${colors["border"]} border-2 rounded-full mx-auto`}>
                            <p className={`font-sans ${colors["text"]} text-xl lg:text-2xl text-center p-2`}> Preencha nosso formulário de orçamento</p>
                        </Link>
                    </div>
                    <div className={"ml-auto w-3/5"}>
                        <img src="/resources/imgs/home/banner.png" alt="BANNER"/>
                    </div>/
                </div>
                <Footer colors={colors}/>
            </div>
        </div>
    );
};

export default Home;