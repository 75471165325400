import React from 'react';

const Footer = ( { colors } ) => {
    return (
        <footer className={`border ${colors["border"]} py-4`}>
            <div className={`container mx-auto text-center default-font text-lg ${colors["text"]}`}>
                Jordhana Raposo Arquitetura & Interiores 2024 - Todos os direitos reservados | Desenvolvido por Letícia Cortez e Otávio Ribas
            </div>
        </footer>
    );
};

export default Footer;